.modal,
.modal-subscribe,
.delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 0px 15px 0px #e9e9e9;
  height: 509px;
}

.modal-subscribe {
  height: 90%;
  width: 60%;
  box-shadow: none;
  overflow: hidden;
}

.delete-modal {
  height: auto;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 3rem;
}

.upload-files {
  height: auto;
  width: 50%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.upload-files label {
  border: dashed 2px #979797 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 80% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1rem;
  margin-top: 1rem;
}

.upload-files label div span {
  font-size: 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.employer-modal {
  width: 40%;
  box-shadow: none;
  font-family: "DM Serif Text", sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
}

.title {
  font-family: "DM Serif Text", sans-serif;
  font-size: 1.7rem;
}

.label {
  font-size: 1.2rem;
}

.desc {
  font-family: "Nunito Sans", sans-serif;
}

input.emails {
  max-height: 100%;
  max-width: 95%;
  padding: 0.8rem;
  margin-top: 0.2rem;
}

.add-btn {
  width: auto;
  background-color: #73314f;
  color: #fff;
  border: none;
  outline: none;
  padding: 1rem 2rem;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 6px;
  font-size: 1rem;
  margin-top: 2rem;
  cursor: pointer;
}

.close-btn {
  color: rgb(68, 68, 68);
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: none;
  position: absolute;
  top: 2rem;
  right: 4rem;
  z-index: 10;
  opacity: 1;
}

.closeModal-btn {
  color: rgb(68, 68, 68);
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 10;
  opacity: 1;
}

.ant-notification .ant-notification-topRight {
  z-index: 100;
}

.employer-modal input#email {
  width: 95%;
  margin-top: 0.3rem;
}

@media screen and (max-width: 1000px) {
  .delete-modal {
    width: 55%;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .modal-subscribe {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .delete-modal,
  .employer-modal {
    width: 85%;
    padding-bottom: 2rem;
  }
  .employer-modal {
    gap: 0;
  }
}
