.swiper {
  width: 734px;
  height: 509px;
}

.swiper-pagination-bullet-active {
  background-color: #73314f;
}

.swiper-pagination-bullet-active,
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullets {
  bottom: 12% !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 990px) {
  .swiper {
    width: 600px;
  }
  .swiper-pagination-bullets {
    bottom: 7% !important;
  }
}

@media only screen and (max-width: 600px) {
  .swiper {
    width: 90vw;
  }
}
